"use client"

import { Button } from "@/components/ui/button"
import Image from "next/image"

export default function Error({ error, reset }) {
  return (
    <div className="h-svh">
      <div className="m-auto flex h-full w-full flex-col items-center justify-center gap-5">
        <div className="relative w-[200px] h-[200px]">
          <Image src={"/images/error-form.svg"} fill alt="" />
        </div>
        <div className="space-y-4 text-center">
          <span className="text-[2rem] md:text-[2rem] font-bold leading-tight ">
            Gagal memuat Halaman!
          </span>
          <p className="text-center md:text-txt16_24 text-muted-foreground">
            Terjadi kesalahan saat Anda memuat halaman. <br />
            Coba cek kembali jaringan internet dan device yang Anda pakai.{" "}
            <br />
            Selanjutnya, Silahkan klik tombol ini untuk muat ulang halaman.
          </p>
        </div>
        <div className="mt-6 flex gap-4">
          <Button
            onClick={
              // Attempt to recover by trying to re-render the segment
              () => reset()
            }>
            Muat Ulang Halaman
          </Button>
        </div>
      </div>
    </div>
  )
}
